<template>
  <!-- 评分 -->
  <div class="wrapper">
    <!-- <el-radio-group v-model="grade">
      <el-radio @click.native.prevent="handleClick(item)" v-for="item in gradeList" :key="item.value" :label="item.value">{{ item.name }}</el-radio>
    </el-radio-group> -->
    <el-form ref="form" :model="formData" label-width="">
      <el-form-item label=" " style="margin-bottom:0">
        <el-radio-group v-model="formData.grade">
          <el-radio @click.native.prevent="handleClick(item)" v-for="item in gradeList" :key="item.value" :label="item.value">{{
            item.name
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- <el-button v-show="grade" style="margin-left:20px" type="" size="mini" @click="addRemark">备注</el-button> -->
    <!-- 备注 -->
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <div class="desc">
        该用户评分为<span>{{ selectedName }}</span
        >，请填写评分备注
      </div>
      <el-input type="textarea" v-model="formData.remark" rows="5" placeholder=""></el-input>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveGrade" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDictEnumListAPI } from '@/enum/dict.js'
import { handleMemberGradeAPI } from './api'
export default {
  name: 'Rate',
  props: {
    memberGradeLog: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    },
    updatePeriod: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      gradeList: [],

      selectedValue: '',
      selectedName: '',
      formData: {
        memberId: this.$route.query.memberId,
        grade: '',
        remark: ''
      }
    }
  },

  mounted() {
    this.getGradeList()
  },
  watch: {
    // grade: {
    //   immediate: true,
    //   handler(newV, oldV) {
    //     this.$store.commit('UPDATE_RATE', this.grade)
    //   }
    // },
    memberGradeLog: {
      immediate: true,
      handler(newV, oldV) {
        this.formData = { ...this.memberGradeLog }
      }
    }
  },
  methods: {
    addRemark() {
      this.dialogVisible = true
      this.remark = ''
    },
    handleInput() {
      // console.log(this.rate)
      // this.$store.commit('UPDATE_RATE', this.rate)
    },
    async getGradeList() {
      const res = await getDictEnumListAPI('member:grade')
      this.gradeList = res
    },
    saveGrade() {
      const { remark } = this.formData
      let data = {
        memberId: this.$route.query.memberId,
        grade: this.selectedValue,
        remark: remark
      }
      handleMemberGradeAPI(data).then(() => {
        this.$message.success('评分成功')
        this.dialogVisible = false
        this.$emit('update:flag', false)
        this.$emit('update:updatePeriod', false)
        this.$emit('updateDetail')
        this.grade = this.selectedValue
      })
    },
    handleClick(item) {
      const { name, value } = item
      this.selectedValue = value
      this.selectedName = name
      this.dialogVisible = true

      if (this.formData.grade == value) {
        // this.formData.remark = this.memberGradeLog.remark || ''
        this.$set(this.formData, 'remark', this.memberGradeLog.remark || '')
      } else {
        // this.formData.remark = ''
        this.$set(this.formData, 'remark', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  .desc {
    margin-bottom: 10px;
    span {
      font-size: 24px;
      font-weight: 600;
      margin: 0 3px;
    }
  }
  .el-dialog__body {
    padding: 40px 30px;
  }
}
</style>
