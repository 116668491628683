<template>
  <div class="wrapper">
    <!-- 基本信息 -->
    <BaseInfo :detailInfo="detailInfo" :flag.sync="flag" @updateDetail="getReviewOrderDetail" />
    <!-- 收货信息 -->
    <div class="detail-title">收货信息</div>
    <Address :flag.sync="flag" :orderAddress="detailInfo.orderAddress" @updateDetail="getReviewOrderDetail" />
    <!-- 工作单位 -->
    <Job
      :updatePeriod.sync="updatePeriod"
      :flag.sync="flag"
      :jobInfo="detailInfo.memberProfile || {}"
      :member="detailInfo.member"
      @updateDetail="getReviewOrderDetail"
    />
    <!-- 紧急联系人 -->
    <Contact :member="detailInfo.member" />
    <!-- 用户评分 -->
    <div class="detail-title">用户评分</div>
    <Rate
      :updatePeriod.sync="updatePeriod"
      :flag.sync="flag"
      :memberGradeLog="detailInfo.memberGradeLog || {}"
      @updateDetail="getReviewOrderDetail"
    />
    <!-- 客户归属 -->
    <div class="detail-title">客户归属</div>
    <SelectCustomer :updatePeriod.sync="updatePeriod" :flag.sync="flag" :kfUserId="detailInfo.kfUserId" @updateDetail="getReviewOrderDetail" />
    <!-- 账期 -->
    <div class="detail-title">账期</div>

    <div style="margin-bottom:20px">
      <Period
        :updatePeriod.sync="updatePeriod"
        :billPeriodNum="detailInfo.billPeriodNum"
        :eachRentPrice="detailInfo.eachRentPrice"
        :prepayPeriodList="detailInfo.prepayPeriodList || []"
      />
    </div>
    <!-- 审核结果 -->

    <!-- <div class="result">
      <div class="title">审核结果</div>
      <AuditResult :memberProfile="detailInfo.memberProfile || {}" :memberGradeLog="detailInfo.memberGradeLog || {}" />
    </div> -->
    <!-- <div style="height:80px"></div> -->
    <div style="height:130px"></div>
  </div>
</template>

<script>
import { getReviewOrderDetailAPI } from './api'
import BaseInfo from './modules/baseInfo.vue'
import Address from './modules/address.vue'
import Rate from './modules/rate.vue'
import SelectCustomer from './modules/select-customer.vue'
import Period from './modules/period.vue'
import AuditResult from './modules/review-result.vue'
import Job from './modules/job.vue'
import Contact from './modules/contact.vue'
export default {
  name: 'DetailBase',
  components: {
    BaseInfo,
    Address,
    Rate,
    SelectCustomer,
    Period,
    Job,
    Contact
  },
  data() {
    return {
      detailInfo: {},
      flag: true,
      updatePeriod: true
    }
  },
  mounted() {
    // this.getReviewOrderDetail()
  },
  activated() {
    // this.flag = false
    this.getReviewOrderDetail()
  },

  methods: {
    async getReviewOrderDetail() {
      this.detailInfo = await getReviewOrderDetailAPI(this.$route.params.id)
      this.$emit('updateDetail', this.detailInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .detail-title {
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 10px 0;
  }
  .result {
    position: sticky;
    bottom: -28px;
    left: 240px;
    right: 0;
    background-color: #fff;
    z-index: 3999;

    .title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      // margin-top: 20px;
    }
  }
}
</style>
