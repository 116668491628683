import { render, staticRenderFns } from "./baseInfo.vue?vue&type=template&id=7cfe4837&scoped=true"
import script from "./baseInfo.vue?vue&type=script&lang=js"
export * from "./baseInfo.vue?vue&type=script&lang=js"
import style0 from "./baseInfo.vue?vue&type=style&index=0&id=7cfe4837&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cfe4837",
  null
  
)

export default component.exports