<template>
  <div class="wrapper">
    <el-checkbox-group v-model="periodList">
      <el-checkbox v-for="item in billPeriodNum" :key="item" :label="item">第{{ item }}期（￥：{{ eachRentPrice }}）</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'Period',
  props: ['billPeriodNum', 'eachRentPrice', 'prepayPeriodList', 'updatePeriod'],
  data() {
    return {
      periodList: []
    }
  },
  watch: {
    periodList: {
      immediate: true,
      handler(newV, oldV) {
        this.$store.commit('UPDATE_PERIOD', this.periodList)
      }
    },
    prepayPeriodList: {
      immediate: true,
      handler(newV, oldV) {
        if (newV && this.updatePeriod) this.periodList = [...this.prepayPeriodList]
      }
    }
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
  padding: 15px;
}
</style>
