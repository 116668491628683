<template>
  <div class="wrapper">
    <el-radio-group v-model="result">
      <el-radio :label="1">同意</el-radio>
      <el-radio :label="2">拒绝</el-radio>
      <el-radio :label="3">取消</el-radio>
    </el-radio-group>

    <div class="submit-btn">
      <!-- <el-button type="" size="small">取消</el-button> -->
      <el-button type="" size="small" @click="closePage">关 闭</el-button>
      <!-- <el-button type="" size="small" @click="test">测试</el-button> -->
      <el-button :disabled="!result" type="primary" size="small" @click="submitResult">提 交</el-button>
    </div>
    <!--  -->
    <el-dialog title="" :visible.sync="dialogVisible" width="50%">
      <!-- 同意 -->
      <div v-show="result == 1" class="content">
        <div class="item">
          <span>
            <span class="my-waring">审核通过</span>
          </span>
          <!-- <span class="my-waring">{{ memberGradeLog.gradeName }}</span> -->
        </div>
        <div class="item">
          <span> 请确认账期：</span>
          <span>
            <el-checkbox-group v-model="periodList">
              <el-checkbox v-for="item in periodList" :key="item" :label="item">第{{ item }}期</el-checkbox>
            </el-checkbox-group>
          </span>
        </div>
        <div class="item">
          <span>选择合同：</span>
          <span>
            <el-radio-group v-model="contractId">
              <el-radio v-for="item in contactList" :key="item.id" :label="item.id">{{ item.contractName }}</el-radio>
            </el-radio-group>
          </span>
        </div>
        <div class="item">
          <span>备注：</span>
          <span>
            <el-input v-model="reviewPass" rows="5" type="textarea" placeholder="请输入备注"></el-input>
          </span>
        </div>
      </div>
      <!-- 拒绝 -->
      <div v-show="result == 2" class="content">
        <div class="tips">审核 <span class="my-waring">拒绝</span> ，请输拒绝入原因</div>
        <el-input v-model="content" rows="5" type="textarea" placeholder=""></el-input>
      </div>
      <!-- 取消 -->
      <div v-show="result == 3" class="content">
        <div class="tips"><span class="my-waring">取消</span>订单，请输入取消原因</div>
        <el-input v-model="remark" rows="5" type="textarea" placeholder=""></el-input>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
        <el-button type="primary" @click="save" size="small">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { handleReviewPassAPI, handleReviewCancelAPI, handleReviewRefuseAPI, getContactListAPI, hitAPI } from './api'
export default {
  name: 'AuditResult',
  props: {
    memberGradeLog: {
      type: Object,
      default: () => {}
    },
    memberProfile: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      result: null,
      content: '',
      remark: '',
      contractId: '',
      contactList: [],
      reviewPass: '',
      
    }
  },
  computed: {
    ...mapState({ rate: state => state.audit.rate, periodList: state => state.audit.periodList })
  },
  mounted() {},

  methods: {
    submitResult() {
      const { workCompany, jobName, income, rentDetail, zmScore } = this.memberProfile
      const res = workCompany && jobName && income && rentDetail && zmScore
      // 同意并且资料为填写
      if (!res && this.result == 1) {
        this.$alert('请先完善用户资料', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      // 同意并且未评分
      // if (!this.memberGradeLog.grade && this.result == 1) {
      //   this.$alert('用户评分不能为空', '提示', {
      //     confirmButtonText: '确定',
      //     type: 'warning'
      //   })
      //   return
      // }
      // this.dialogVisible = true
      this.$emit('result', this.result)
      // if (this.result == 1) {
      //   this.getContactList()
      //   this.contractId = ''
      // }
    },
    save() {
      this.dialogVisible = false
      if (this.result == 1) {
        if (!this.contractId) {
          this.$alert('请选择合同!', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          })
          this.dialogVisible = true
          return
        }
        if (!this.reviewPass) {
          this.$alert('请填写备注!', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          })
          this.dialogVisible = true
          return
        }
        let data = {
          orderNo: this.$route.params.id,
          prepayPeriodList: this.periodList,
          contractId: this.contractId,
          remark: this.reviewPass
        }
        handleReviewPassAPI(data).then(() => {
          // this.$router.push({ name: 'ReviewList' })
          this.$message.success('审核同意成功，即将关闭本页面...')
          setTimeout(() => {
            this.emitAwindow()
            window.close()
          }, 2000)
        })
      } else if (this.result == 2) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.content
        }
        handleReviewRefuseAPI(data).then(() => {
          // this.$router.push({ name: 'ReviewList' })

          this.$message.success('拒绝成功，即将关闭本页面...')
          setTimeout(() => {
            this.emitAwindow()
            window.close()
          }, 2000)
        })
      } else if (this.result == 3) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.remark
        }
        handleReviewCancelAPI(data).then(() => {
          // this.$router.push({ name: 'ReviewList' })

          this.$message.success('订单取消成功，即将关闭本页面...')
          setTimeout(() => {
            this.emitAwindow()
            window.close()
          }, 2000)
        })
      }
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        console.log(1, window.opener)
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    },
    closePage() {
      this.emitAwindow()
      window.close()
    },
    async getContactList() {
      const res = await getContactListAPI()
      this.contactList = res || []
    },
    beforeClose() {}
 
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  .content {
    margin-top: 20px;
    .item {
      display: flex;
      margin: 15px 0;
      & > span {
        &:first-child {
          white-space: nowrap;
          margin-right: 10px;
        }
      }
      &:nth-child(n + 2) {
        & > span {
          &:first-child {
            width: 80px;
            text-align: right;
          }
          &:last-child {
            flex: 1;
          }
        }
      }
    }
    .tips {
      margin: 5px 0;
      // font-weight: 600;
    }
  }
  .submit-btn {
    text-align: right;
    margin-top: 20px;
  }
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .el-dialog__header {
    padding-top: 0;
  }
  .el-dialog__footer {
    padding: 40px 20px;
  }
  .my-waring {
    color: #f01616;
    font-weight: 600;
    font-size: 18px;
  }
}
</style>
